import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { BreadCrumbComponent } from '@shared/layout';

@Component({
  selector: 'app-page-header',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BreadCrumbComponent],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
})
export class PageHeaderComponent {
  title = input.required<string>();
  showBreadCrumb = input(true);
}
