import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '@core/types';

@Pipe({
  name: 'nullish',
  standalone: true, // For Angular 14+
})
export class NullishReplacementPipe implements PipeTransform {
  /**
   * Transforms input value, replacing null/undefined/empty with a specified character
   * @param value - The input value to transform
   * @param replacementChar - Character to use for replacement (default: '-')
   * @param trimBeforeCheck - Whether to trim the value before checking (default: true)
   * @returns Transformed value
   */
  transform(value: SafeAny, replacementChar = '-', trimBeforeCheck = true): string {
    // Handle null or undefined immediately
    if (value === null || value === undefined) {
      return replacementChar;
    }

    // Convert to string
    let stringValue = String(value);

    // Trim if specified
    if (trimBeforeCheck) {
      stringValue = stringValue.trim();
    }

    // Return replacement if empty
    return stringValue === '' ? replacementChar : stringValue;
  }
}
