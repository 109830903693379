import { Injectable, TemplateRef } from '@angular/core';
import { SafeAny } from '@core/types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private drawerSubject = new Subject<{
    template: TemplateRef<SafeAny> | null;
    context?: SafeAny;
  }>();
  drawer$ = this.drawerSubject.asObservable();
  isDrawerOpen = false;

  open(template: TemplateRef<SafeAny>, context?: SafeAny) {
    this.drawerSubject.next({ template, context });
    this.isDrawerOpen = true;
  }

  close() {
    this.drawerSubject.next({ template: null });
    this.isDrawerOpen = false;
  }
}
