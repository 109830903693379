import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CopyDirective } from '@shared/directives';
import { NullishReplacementPipe, TruncatePipe } from '@shared/pipes';

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-user-info-td',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CopyDirective, NullishReplacementPipe, TruncatePipe],
  templateUrl: './user-info-td.component.html',
  styleUrl: './user-info-td.component.scss',
})
export class UserInfoTdComponent {
  user = input.required<UserInfo>();
  enableCopy = input(true);
  enableTruncate = input(true);
}
