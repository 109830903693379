import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CurrencyAmount } from '@core/models';
import { NullishReplacementPipe, TrimTrailingZerosPipe } from '@shared/pipes';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-currency-exchange-td',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxMaskPipe, NullishReplacementPipe, TrimTrailingZerosPipe],
  providers: [provideNgxMask()],
  templateUrl: './currency-exchange-td.component.html',
  styleUrl: './currency-exchange-td.component.scss',
})
export class CurrencyExchangeTdComponent {
  receiveCurrency = input.required<CurrencyAmount>();
  payCurrency = input.required<CurrencyAmount>();
}
