import { SafeAny } from '@core/types';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface HttpRequestState<T> {
  loading: boolean;
  value?: T;
  error?: Error;
}
export function wrapWithinHttpRequestState<T>(source: Observable<T>) {
  return source.pipe(
    map((value: SafeAny) => ({ loading: false, value })),
    startWith({ loading: true }),
    catchError((error) => of({ loading: false, error })),
  );
}
export class ObservableLoadingTransformer {
  /**
   * Transforms an observable to include loading state information
   * @param source The source observable to transform
   * @returns An observable that emits loading state information along with the value
   */
  static withLoading<T>(source: Observable<T>): Observable<HttpRequestState<T>> {
    return wrapWithinHttpRequestState(source);
  }

  /**
   * Creates a new observable with loading state from an async operation
   * @param asyncOperation A function that returns a promise or observable
   * @returns An observable that includes loading state
   */
  static fromAsync<T>(asyncOperation: () => Observable<T>): Observable<HttpRequestState<T>> {
    const source = asyncOperation();

    return wrapWithinHttpRequestState(source);
  }
}
