import { AuthService, PermissionService } from '@core/services';
import { Credentials } from '@core/dtos';
import { jwtCredentials } from '@core/signals';
import { catchError, throwError } from 'rxjs';

export function authInitializer(authService: AuthService): Promise<boolean> {
  return new Promise((resolve) => {
    const credentials = authService.restoreCredentials() as Credentials;
    if (credentials) {
      jwtCredentials.set({
        accessToken: credentials.accessToken,
        refreshToken: credentials.refreshToken,
      });
      resolve(true);
    } else {
      jwtCredentials.set(null);
      resolve(false);
    }
  });
}
export function permissionsInitializer(
  permissionService: PermissionService,
  authService: AuthService,
): Promise<boolean> {
  return new Promise((resolve) => {
    const token = jwtCredentials()?.accessToken;

    if (token) {
      permissionService
        .loadUserPermissions()
        .pipe(
          catchError((error) => {
            authService.logout();
            return throwError(() => error);
          }),
        )
        .subscribe({
          next: () => resolve(true),
          error: () => resolve(false),
        });
    } else {
      resolve(false);
    }
  });
}
