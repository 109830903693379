import { PaymentStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';

export function getPaymentStatus(kycStatus: PaymentStatusEnum): VerificationStatus {
  const statusMap: Record<PaymentStatusEnum, VerificationStatus> = {
    [PaymentStatusEnum.None]: { text: 'None', color: 'gray', icon: 'fa-solid fa-circle' },
    [PaymentStatusEnum.New]: { text: 'New', color: 'yellow-light', icon: 'fa-solid fa-star' },
    [PaymentStatusEnum.Paying]: {
      text: 'Paying',
      color: 'blue-light',
      icon: 'fa-solid fa-times-circle',
    },
    [PaymentStatusEnum.Pending]: {
      text: 'Pending',
      color: 'blue-light',
      icon: 'fa-solid fa-hourglass-half',
    },
    [PaymentStatusEnum.Completed]: {
      text: 'Completed',
      color: 'green-light',
      icon: 'fa-solid fa-check',
    },
    [PaymentStatusEnum.Rejected]: {
      text: 'Rejected',
      color: 'red',
      icon: 'fa-solid fa-times-circle',
    },
    [PaymentStatusEnum.Refunded]: {
      text: 'Refunded',
      color: 'blue-light',
      icon: 'fa-solid fa-undo',
    },
    [PaymentStatusEnum.Failed]: { text: 'Failed', color: 'red-light', icon: 'fa-solid fa-times' },
    [PaymentStatusEnum.Exception]: {
      text: 'Exception',
      color: 'red-light',
      icon: 'fa-solid fa-exclamation-triangle',
    },
  };

  return statusMap[kycStatus] || { text: 'Unknown', color: 'black', icon: 'fa-question-circle' };
}
