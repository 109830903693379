<div class="flex flex-col gap-1">
  <span class="Body-1">
    @let receiveCurrencyPrecision = receiveCurrency().decimalPrecision;
    {{
      receiveCurrency().amount
        | trimTrailingZeros: receiveCurrencyPrecision
        | mask: 'separator.' + receiveCurrencyPrecision : { thousandSeparator: ',' }
    }}
    {{ receiveCurrency().code | nullish }}
  </span>
  <span class="Caption text-secondary-70">
    @let payCurrencyPrecision = payCurrency().decimalPrecision;

    {{
      payCurrency().amount
        | trimTrailingZeros: payCurrencyPrecision
        | mask: 'separator.' + payCurrencyPrecision : { thousandSeparator: ',' }
    }}
    {{ payCurrency().code | nullish }}
  </span>
</div>
