import { Pipe, PipeTransform } from '@angular/core';
import { VerificationRequestStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';
import { getVerificationRequestStatus } from '@core/utils';

@Pipe({
  name: 'verificationRequestStatus',
  standalone: true,
})
export class VerificationRequestStatusPipe implements PipeTransform {
  transform(status: VerificationRequestStatusEnum): VerificationStatus {
    return getVerificationRequestStatus(status);
  }
}
