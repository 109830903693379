import { inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MASK_CONFIG } from '@core/configs';
import { VALIDATION_ERROR_MESSAGES } from '@core/constants';
import { ValidationError } from '@core/interfaces';
export type ExtractFormControlValue<T> = {
  [K in keyof T]: T[K] extends FormControl<infer U>
    ? NonNullable<U>
    : T[K] extends FormGroup<infer FG>
      ? ExtractFormControlValue<FG>
      : T[K] extends FormArray<infer FA>
        ? ExtractFormControlValue<FA>[]
        : never;
};
export function getInputFirstErrorMessage(errors: ValidationErrors | null): ValidationError {
  let error: ValidationError = { message: '' };
  if (errors) {
    const firstError = Object.keys(errors!).map((key) => ({ error: errors![key], key }))[0];
    if (typeof firstError.error === 'boolean')
      error = VALIDATION_ERROR_MESSAGES[firstError.key]({});
    else error = firstError.error;
  }
  return error;
}

// Define the MaskConfig type
export interface MaskConfig {
  precision: number;
  mask: string;
  digitLimit: number;
  separatorLimit: string;
  thousandSeparator?: string;
  decimalMarker?: string;
  allowNegativeNumbers?: boolean;
  dropSpecialCharacters: boolean;
}

export class MaskGenerator {
  readonly config: MaskConfig;
  /**
   *
   */
  constructor() {
    this.config = inject(MASK_CONFIG);
  }
  /**
   * Generates mask configuration based on provided options
   * @param config Mask configuration options
   * @returns Object with mask properties
   */
  generate(config?: Partial<MaskConfig>): MaskConfig {
    const { precision, digitLimit, allowNegativeNumbers, thousandSeparator, decimalMarker } = {
      ...this.config,
      ...config,
    };

    const actualPrecision =
      precision != null
        ? Math.min(Math.max(0, precision), this.config.precision)
        : this.config.precision;

    return {
      precision: actualPrecision,
      digitLimit,
      mask: `separator.${actualPrecision}`,
      separatorLimit: '9'.repeat(digitLimit),
      thousandSeparator,
      decimalMarker,
      allowNegativeNumbers: allowNegativeNumbers,
      dropSpecialCharacters: false,
    };
  }
}
