import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ibanFormat',
  standalone: true,
})
export class IbanFormatPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }

    // Remove any existing spaces first
    const cleanValue = value.replace(/\s/g, '');

    // Check if it already starts with 'IR'
    if (cleanValue.startsWith('IR')) {
      return cleanValue.replace(/^IR/, 'IR ');
    }

    // If it doesn't start with 'IR', add it
    return `IR ${cleanValue}`;
  }
}
