@let inputLabel = label();
<mat-form-field class="hide-hint">
  <mat-label>{{ inputLabel }}</mat-label>
  <input
    matInput
    type="password"
    [formControl]="control"
    autocomplete="new-password"
    required
    #passwordInput
  />
  <app-password-toggler matSuffix [inputElement]="passwordInput" />
</mat-form-field>

<div class="grid grid-cols-1 xl:grid-cols-2 gap-2 ml-l mt-s validation-messages">
  @for (condition of conditions; track $index) {
    <span
      [ngClass]="{
        'text-green-base': (formGroup.submitted || !control.pristine) && condition.met,
        'text-secondary-70': !formGroup.submitted && control.pristine,
        'mat-mdc-form-field-error': (formGroup.submitted || !control.pristine) && !condition.met,
      }"
    >
      {{ condition.text }}
    </span>
  }
</div>
