import { VerificationRequestStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';

export function getVerificationRequestStatus(
  status: VerificationRequestStatusEnum,
): VerificationStatus {
  const statusMap: Record<VerificationRequestStatusEnum, VerificationStatus> = {
    [VerificationRequestStatusEnum.Approved]: {
      text: 'Approved',
      color: 'green',
      icon: 'fa-solid fa-check',
    },
    [VerificationRequestStatusEnum.Limited]: {
      text: 'Limited',
      color: 'red',
      icon: 'fa-solid fa-exclamation',
    },
    [VerificationRequestStatusEnum.Rejected]: {
      text: 'Rejected',
      color: 'red-light',
      icon: 'fa-solid fa-xmark',
    },
    [VerificationRequestStatusEnum.Exception]: {
      text: 'Exception',
      color: 'red-light',
      icon: 'fa-solid fa-exclamation-triangle',
    },
    [VerificationRequestStatusEnum.Failed]: {
      text: 'Failed',
      color: 'red-light',
      icon: 'fa-solid fa-times-circle',
    },
    [VerificationRequestStatusEnum.AwaitingApproval]: {
      text: 'Awaiting Approval',
      color: 'blue-light',
      icon: 'fa-solid fa-hourglass-half',
    },
    [VerificationRequestStatusEnum.UserApproved]: {
      text: 'User Approved',
      color: 'green-light',
      icon: 'fa-solid fa-hourglass-half',
    },
    [VerificationRequestStatusEnum.OnHold]: {
      text: 'On Hold',
      color: 'blue-light',
      icon: 'fa-solid fa-pause',
    },
    [VerificationRequestStatusEnum.Inquired]: {
      text: 'Inquired',
      color: 'blue-light',
      icon: 'fa-solid fa-envelope',
    },
    [VerificationRequestStatusEnum.Inquiring]: {
      text: 'Inquiring',
      color: 'blue-light',
      icon: 'fa-solid fa-question',
    },
    [VerificationRequestStatusEnum.Processing]: {
      text: 'Processing',
      color: 'blue',
      icon: 'fa-solid fa-spinner',
    },
    [VerificationRequestStatusEnum.Queued]: {
      text: 'Queued',
      color: 'blue-light',
      icon: 'fa-solid fa-clock',
    },
    [VerificationRequestStatusEnum.New]: { text: 'New', color: 'yellow' },
  };

  return statusMap[status];
}
