<mat-chip-listbox>
  @for (chipTitle of chipsTitleList(); track chipTitle; let first = $first) {
    <mat-chip-option
      [selected]="first"
      (selectionChange)="onSelectedChipChange(chipTitle)"
      [ngClass]=" chipTitle === selectedChipName ? 'pointer-events-none' : ''">
      {{ chipTitle }}
    </mat-chip-option>
  }
</mat-chip-listbox>
<ng-content></ng-content>
