<mat-form-field class="w-full">
  <mat-label>{{ label() }}</mat-label>
  <input
    type="text"
    matInput
    [placeholder]="placeholder()"
    [formControl]="control"
    [matAutocomplete]="auto"
    [attr.aria-label]="label"
  />
  <mat-error appMatErrorMessages />

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="_display"
    (optionSelected)="onOptionSelected($event)"
  >
    @for (option of filteredOptions$ | async; track option) {
      <mat-option [value]="_display(option)">
        <ng-container
          *ngTemplateOutlet="
            optionTemplate() || defaultOptionTemplate;
            context: { $implicit: option }
          "
        >
        </ng-container>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>

<ng-template #defaultOptionTemplate let-option>
  {{ _display(option) }}
</ng-template>
