import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiLine',
  standalone: true,
})
export class MultiLinePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    // Split by periods, add back the period, then join with <br>
    return value
      .split('.')
      .filter((sentence) => sentence.trim().length > 0)
      .map((sentence) => sentence.trim() + '.')
      .join('<br>');
  }
}
