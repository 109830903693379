import { Directive, ElementRef, Renderer2, AfterViewInit, inject, input } from '@angular/core';
import { ClipboardService } from '@core/services';

@Directive({
  selector: '[appCopyInput]',
  standalone: true,
})
export class CopyInputDirective implements AfterViewInit {
  elementRef = inject(ElementRef);
  renderer = inject(Renderer2);
  clipboardService = inject(ClipboardService);
  clearSpaces = input(true);
  ngAfterViewInit() {
    this.addCopyFunctionality();
  }

  private addCopyFunctionality() {
    const ancestor = this.elementRef.nativeElement.parentElement.parentElement;

    if (ancestor) {
      const button = ancestor.querySelector(
        'button[mat-icon-button], button[matSuffix], button[matPrefix]',
      );
      if (button) {
        this.renderer.listen(button, 'click', () => {
          const replacedString = this.elementRef.nativeElement.value.trim();
          this.clipboardService.copyText(
            this.clearSpaces() ? replacedString.replace(/\s+/g, '') : replacedString,
          );
        });
      } else {
        throw Error('Button with mat-icon-button not found inside MatSuffix');
      }
    } else {
      throw Error('Parent element not found');
    }
  }
}
