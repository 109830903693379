import { Pipe, PipeTransform } from '@angular/core';
import { PaymentStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';
import { getPaymentStatus } from '@core/utils';

@Pipe({
  name: 'paymentStatus',
  standalone: true,
})
export class PaymentStatusPipe implements PipeTransform {
  transform(status: PaymentStatusEnum): VerificationStatus {
    return getPaymentStatus(status);
  }
}
