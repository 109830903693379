import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-dialog-title',
  standalone: true,
  imports: [NgClass, MatDialogClose],
  templateUrl: './mat-dialog-title.component.html',
  styleUrl: './mat-dialog-title.component.scss',
})
export class MatDialogTitleComponent {
  title = input.required<string>();
  titleClasses = input('m-0 Subtitle-1 text-secondary-50 dialog-title');
  closeIconClasses = input('dialog-close-icon fa-solid fa-xmark');
  showCloseIcon = input(true);
}
