import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { HasPermissionDirective } from '@shared/directives';
import { NavItem } from '@shared/layout';
import { NgClass, NgTemplateOutlet } from '@angular/common';
@Component({
  selector: 'app-nav-item',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    MatListModule,
    MatExpansionModule,
    HasPermissionDirective,
    NgTemplateOutlet,
    NgClass,
  ],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss',
})
export class NavItemComponent {
  navItem = input.required<NavItem>();
  router = inject(Router);

  shouldExpand(navItem: NavItem): boolean {
    if (this.router.url.startsWith(navItem.link)) {
      return true;
    }
    if (navItem.children) {
      return navItem.children.some((child: NavItem) => this.router.url.startsWith(child.link));
    }
    return false;
  }
}
