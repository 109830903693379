import { inject, Pipe, PipeTransform } from '@angular/core';
import { MASK_CONFIG } from '@core/configs';

@Pipe({
  name: 'parseAndFormatNumbers',
  standalone: true,
})
export class ParseAndFormatNumbersPipe implements PipeTransform {
  maskConfig = inject(MASK_CONFIG);
  /**
   * Transforms a string by finding and formatting numbers
   * @param value The input string containing numbers
   * @param precision Optional precision for decimal numbers (default: 2)
   * @returns Formatted string with comma-separated numbers
   */
  transform(value: string, precision?: number): string {
    if (!value) return value;

    // Regular expression to find numbers (integer or decimal)
    const numberRegex = /\b(\d+(?:\.\d+)?)\b/g;

    // Replace numbers with formatted versions
    return value.replace(numberRegex, (match) => {
      // Check if the original match includes leading zeros
      const hasLeadingZeros = /^0+\d+$/.test(match);

      // Parse the number
      const num = parseFloat(match);

      // Format with comma separators and specified precision
      const formattedNum = this._formatNumber(num, precision ?? this.maskConfig.precision, match);

      // If original number had leading zeros, preserve them
      return hasLeadingZeros ? match.replace(num.toString(), formattedNum) : formattedNum;
    });
  }

  /**
   * Format a number with comma separators and specified precision
   * @param num Number to format
   * @param precision Decimal precision
   * @param originalMatch Original matched string for reference
   * @returns Formatted number string
   */
  private _formatNumber(num: number, precision: number, originalMatch: string): string {
    // Check if the original number was a decimal
    const isDecimal = originalMatch.includes('.');

    if (isDecimal) {
      // For decimal numbers, use toFixed to maintain precision
      const formattedNum = num.toLocaleString(undefined, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });
      return formattedNum;
    } else {
      // For integers, use toLocaleString() for comma separation
      return num.toLocaleString();
    }
  }
}
