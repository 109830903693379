import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';

@Component({
  selector: 'app-mat-chip-tab',
  standalone: true,
  imports: [MatChipListbox, MatChipOption, NgClass],
  templateUrl: './mat-chip-tab.component.html',
  styleUrl: './mat-chip-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MatChipTabComponent {
  chipsTitleList = input<string[]>();
  onNewChipSelect = output<number>();
  selectedChipName: string | undefined;

  onSelectedChipChange(selectedChipValue: string) {
    const selectedChipIndex = this.chipsTitleList()?.findIndex(
      (item) => item === selectedChipValue,
    );
    this.selectedChipName = selectedChipValue;
    if (selectedChipIndex !== undefined) {
      this.onNewChipSelect.emit(selectedChipIndex);
      return;
    }
  }
}
