@if (error()) {
  <div class="flex flex-col items-center justify-center h-full text-center w-[322px] mx-auto">
    @if (showIcon()) {
      <div class="circle flex items-center justify-center w-24 h-24 rounded-full opacity-100">
        <i class="icon" [ngClass]="iconName()"></i>
      </div>
    }
    <div class="texts">
      @if (showTitle()) {
        <h2 class="Headline-6 text-secondary-10 mmb-[16px] mt-0">
          {{ title() }}
        </h2>
      }
      @if (showDescription()) {
        <p
          class="Body-3 text-secondary-60 mt-0 px-l"
          [innerHTML]="description() || error()?.message! | multiLine"
        ></p>
      }
    </div>
    <button
      mat-stroked-button
      class="h-9 w-[322px]"
      color="primary"
      (click)="retryProcess()"
      type="button"
    >
      Refresh
    </button>
  </div>
} @else {
  <ng-content></ng-content>
}
