import { Component, inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Tab, TabNavbarConfig } from '@core/models';
import { HasPermissionDirective } from '@shared/directives';
import { PageHeaderComponent } from '@shared/layout';

@Component({
  selector: 'app-tab-nav-bar',
  standalone: true,
  imports: [
    RouterModule,
    MatTabsModule,
    PageHeaderComponent,
    MatCardModule,
    HasPermissionDirective,
  ],
  templateUrl: './tab-nav-bar.component.html',
  styleUrl: './tab-nav-bar.component.scss',
})
export class TabNavBarComponent implements OnInit {
  tabs!: Tab[];
  title = 'Page title goes here';
  config!: TabNavbarConfig;
  private readonly _route = inject(ActivatedRoute);
  ngOnInit() {
    this.title = this._route.snapshot.data['title'] || this.title;
    this.tabs = this._route.snapshot.data['tabs'] || [];
    this.config = this._route.snapshot.data['navbarConfig'] || {
      titleVisible: true,
    };
  }
}
