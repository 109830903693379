export enum PaymentStatusEnum {
  None = 0,
  New = 1,
  Paying = 5,
  Pending = 6,
  Completed = 20,
  Rejected = 21,
  Refunded = 22,
  Failed = 23,
  Exception = 24,
}
