@let optionTemplate = content();
@let showAllOption = selectAllOptionVisible();
<mat-form-field class="w-full" (click)="onOpenChange()">
    <mat-label>{{label()}}</mat-label>
    <mat-select class="app-mat-mdc-select" [(value)]="value"
        (selectionChange)="selectionChange($event)" (closed)="onTouched()" [required]="required()" >
        <mat-select-trigger>
          @if (showAllOption && !value) {
            <span>All</span>
          }
          @if (value) {
            <ng-container>
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: value }"></ng-container>
            </ng-container>
          }
        </mat-select-trigger>
        @if(filterable()){
        <div class="mx-xs mt-s">
            <app-search-box [label]="'Search'" filterable (searchQueryChange)="search($event)"/>
            <mat-divider></mat-divider>
        </div>
        }
        <div class="app-mat-select-options app-tiny-scrollbar app-scrollbar--default" appScrollEnd (scrollEnd)="onScroll()">
            @if(showAllOption){
            <mat-option [value]="''">All</mat-option>
            }
          @if (isLoading && items.length === 0){
            <mat-option disabled class="text-center">Loading...</mat-option>
          }
          @if (errorMessage){
            <mat-option disabled class="text-center">{{errorMessage}}</mat-option>
          }
          @for (item of items; track item){
            <mat-option [value]="item">
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item }"></ng-container>
            </mat-option>
          }
          @if (isLoading && items.length > 0){
            <mat-option disabled class="text-center">Loading more...</mat-option>
          }
          @if (!isLoading && !hasMoreData && items.length > 0){
            <mat-option disabled class="text-center">No more items</mat-option>
          }
          @if (!isLoading && items.length == 0 && searchQuery && !errorMessage){
            <mat-option disabled class="text-center">Not Result</mat-option>
          }
        </div>
    </mat-select>
    @if(value){
    <button matSuffix mat-icon-button aria-label="Clear" (click)="clear()" class="btn-small" type="button">
        <span class="fa-solid fa-xmark text-secondary-60"></span>
    </button>
    }
</mat-form-field>
