<mat-card appearance="outlined" class="app-mat-mdc-card-outlined mb-l p-xxl">
  <mat-card-header class="mb-xl">
    <mat-card-title-group>
      <mat-card-title>{{ title() }}</mat-card-title>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content class="px-0">
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions class="flex justify-end px-0">
    @if(collapsible){
    <button mat-button class="align-self-start" (click)="toggleFilters()">
      <span class="text-secondary-60">
        {{ collapsed ? 'Less Filter' : 'More Filter' }}
        <span class="fa-regular" [ngClass]="{'fa-chevron-up': collapsed, 'fa-chevron-down': !collapsed}"></span>
      </span>
    </button>
    }
    <div class="flex flex-1 justify-end space-x-2">
      <button mat-button color="primary" (click)="clearFilters()">Clear</button>
      <button mat-stroked-button color="primary" (click)="onSubmit()">Search</button>
    </div>
  </mat-card-actions>
</mat-card>