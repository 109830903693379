import { Component, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataTableFilterService } from '@shared/services';

@Component({
  template: '',
  standalone: true,
})
export abstract class DataTableFilterBaseComponent {
  filterForm!: FormGroup;

  abstract buildFilterForm(): void;

  dataTableFilterService = inject(DataTableFilterService);

  get collapsed() {
    return this.dataTableFilterService.collapsed;
  }
}
