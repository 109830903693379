import { Component, input } from '@angular/core';
import { VerificationHistory } from '@core/models';
import { StatusComponent } from '../status/status.component';
import {
  KycStatusPipe,
  NullishReplacementPipe,
  VerificationRequestStatusPipe,
} from '@shared/pipes';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-verfication-history',
  standalone: true,
  imports: [
    StatusComponent,
    KycStatusPipe,
    VerificationRequestStatusPipe,
    DatePipe,
    NullishReplacementPipe,
  ],
  templateUrl: './verfication-history.component.html',
  styleUrl: './verfication-history.component.scss',
})
export class VerficationHistoryComponent {
  histories = input.required<VerificationHistory[]>();
}
