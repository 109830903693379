export enum KYCStatusEnum {
  Verified = 1,
  UnVerified = 2,
  Waiting = 3,
  Rejected = 4,
}
export enum VerificationRequestStatusEnum {
  New = 1,
  Queued = 5,
  Processing = 6,
  Inquiring = 7,
  Inquired = 8,
  OnHold = 9,
  AwaitingApproval = 10,
  UserApproved = 11,
  Approved = 20,
  Rejected = 21,
  Failed = 22,
  Exception = 23,
  Limited = 24,
}
export enum BankAccountVerificationStatusEnum {
  Pending = 1,
  Accepted = 2,
  Rejected = 3,
}
