import { Directive, HostListener, inject, output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appValidSubmit]',
  standalone: true,
})
export class ValidSubmitDirective {
  formGroupDirective = inject(FormGroupDirective);
  onSubmit = output();

  @HostListener('submit', ['$event'])
  submit(event: Event) {
    event.preventDefault();

    if (this.formGroupDirective.form.valid) {
      this.onSubmit.emit();
    }
  }
}
