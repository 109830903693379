<span class="sort-indicator" [ngSwitch]="direction()">
    <span *ngSwitchCase="'asc'">
        <i class="fa-solid fa-sort-up"></i>
    </span>
    <span *ngSwitchCase="'desc'">
        <i class="fa-solid fa-sort-down"></i>
    </span>
    <span *ngSwitchDefault>
        <i class="fa-regular fa-sort"></i>
    </span>
</span>