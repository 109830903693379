import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToastType } from '@core/types';
import { getToastIcon } from '@core/utils';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-app-snack-bar',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, NgClass],
  templateUrl: './app-snack-bar.component.html',
  styleUrl: './app-snack-bar.component.scss',
})
export class AppSnackbarComponent {
  data = inject(MAT_SNACK_BAR_DATA);
  icon(type: ToastType) {
    return getToastIcon(type);
  }
}
