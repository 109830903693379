import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionCode } from '@core/enums';
import { PermissionService } from '@core/services';

@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective {
  private _requiredPermissions: PermissionCode[] | undefined = [];
  private _permissionsService = inject(PermissionService);
  private _templateRef = inject(TemplateRef);
  private _viewContainer = inject(ViewContainerRef);

  /**
   * Input property to set the required permissions for this directive.
   * It accepts an array of PermissionCode values and updates the view based on permission checks.
   */
  @Input({ alias: 'appHasPermission' })
  set hasPermission(permissions: PermissionCode[] | undefined) {
    this._requiredPermissions = permissions;
    this.updateView();
  }

  /**
   * Updates the view based on whether the user has the required permissions.
   * If the user has any of the required permissions, the template is rendered; otherwise, it's cleared.
   */
  private updateView(): void {
    if (this._permissionsService.hasAnyPermission(this._requiredPermissions)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}
