import { generateRoutePermissions, generateUUID, mergePermissions } from '@core/utils';
import { NavItem } from './nav-item';
import { PermissionCode } from '@core/enums';

/**
 * This constant contains all the links that will be displayed in the sidebar.
 *
 * - If a route's permissions are left empty, it indicates that no permissions are required
 *   for accessing that route (or it is not critical enough to require permission checks).
 *
 * - When merging extra permissions for main routes (e.g., "Exchange Rate"), make sure
 *   to specify permissions directly on the main route. The permission merge function
 *   will then combine the main route's permissions with those of its child routes.
 */
export const navItems: NavItem[] = [
  {
    id: generateUUID(),
    enable: true,
    label: 'Dashboard',
    icon: 'fa-regular fa-house',
    link: '/cpl/dashboard',
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Exchange Rate',
    icon: 'fa-regular fa-rotate',
    link: '/cpl/exchange-rates',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Update Rate',
        icon: 'fa-solid fa-circle',
        link: '/cpl/exchange-rates/update',
        permissions: [PermissionCode.UpdateRate],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Reports',
        icon: 'fa-solid fa-circle',
        link: '/cpl/exchange-rates/reports',
        permissions: [PermissionCode.Report],
      },
    ],
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Verification',
    icon: 'fa-regular fa-circle-check',
    link: '/cpl/verification',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'KYC Requests',
        icon: 'fa-solid fa-circle',
        link: '/cpl/verification/KYCs',
        permissions: [PermissionCode.KycRequests],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Bank Verify',
        icon: 'fa-solid fa-circle',
        link: '/cpl/verification/banks',
        permissions: [PermissionCode.BankVerify],
      },
    ],
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Requests',
    icon: 'fa-regular fa-hand',
    link: '/cpl/requests',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Deposit',
        icon: 'fa-solid fa-circle',
        link: '/cpl/requests/deposits',
        permissions: [PermissionCode.Deposit],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Withdrawal',
        icon: 'fa-solid fa-circle',
        link: '/cpl/requests/withdrawals',
        permissions: [PermissionCode.Withdraw],
      },
    ],
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'User Management',
    icon: 'fa-regular fa-user-gear',
    link: '/cpl/user-management',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Customers',
        icon: 'fa-solid fa-circle',
        link: '/cpl/user-management/customers',
        permissions: [PermissionCode.Customers],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Experts',
        icon: 'fa-solid fa-circle',
        link: '/cpl/user-management/experts',
        permissions: [PermissionCode.Experts],
      },
    ],
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'IPG',
    icon: 'fa-regular fa-globe',
    link: '/cpl/IPG',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Partner',
        icon: 'fa-solid fa-circle',
        link: '/cpl/IPG/partners',
        permissions: [PermissionCode.PartnerManagement],
      },
    ],
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Wallet Management',
    icon: 'fa-regular fa-credit-card',
    link: '/cpl/wallet-management',
    permissions: [PermissionCode.WalletManagement],
  },
  {
    id: generateUUID(),
    enable: true,
    label: 'Configuration',
    icon: 'fa-regular fa-sliders',
    link: '/cpl/configuration',
    children: [
      {
        id: generateUUID(),
        enable: true,
        label: 'Banks Management',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configuration/banks',
        permissions: [PermissionCode.BankManagement],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Currencies',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configuration/currencies',
        permissions: [PermissionCode.Currency],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'KYC Providers',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configuration/kyc-providers',
        permissions: [PermissionCode.KycProvider],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Payment Providers',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configuration/payment-providers',
        permissions: [PermissionCode.PaymentProvider],
      },
      {
        id: generateUUID(),
        enable: true,
        label: 'Payment Methods',
        icon: 'fa-solid fa-circle',
        link: '/cpl/configuration/payment-methods',
        permissions: [PermissionCode.PaymentMethod],
      },
    ],
  },
];

/**
 * Merges all permissions from sub-routes into each main route's permissions.
 *
 * For example, if we reach the 'Exchange Rate' route and its child routes have
 * permissions like 'UpdateRate' and 'Report', the main route will inherit these
 * permissions ([UpdateRate, Report]). This enables control over the visibility
 * of the parent navigation item in the sidebar, ensuring it appears if the user
 * has at least one of the required permissions from any child.
 *
 * The `mergePermissions` function is used here to recursively collect permissions,
 * stopping further checks if a child route lacks permissions, which allows the
 * parent route to be shown unconditionally.
 *
 * @param {NavItem[]} navItems - An array of navigation items to process.
 */
function processNavItems(navItems: NavItem[]): void {
  navItems.forEach((navItem) => {
    const mergedPermissions = mergePermissions(navItem);
    navItem.permissions = mergedPermissions;
  });
}

// Use the function to process navItems
processNavItems(navItems);

/**
 * In this step, we create key-value pairs using the permissions calculated earlier.
 * This will consolidate all the main sidebar routes and their sub-route permissions
 * into a single object for use in permission guards, helping to keep the route
 * configuration files cleaner.
 * For example, the '/cpl/exchange-rate' route with '/update' and '/report' sub-routes
 * will be structured like this:
 *
 * {
 *   '/cpl/exchange-rate': [1, 2],
 *   '/cpl/exchange-rate/update': [1],
 *   '/cpl/exchange-rate/report': [2]
 * }
 */
export const routePermissions = generateRoutePermissions(navItems);
