import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  OnInit,
  inject,
  input,
} from '@angular/core';
import { ClipboardService } from '@core/services';
import { SafeAny } from '@core/types';

@Directive({
  selector: '[appCopy]',
  standalone: true,
})
export class CopyDirective implements OnInit {
  clipboardService = inject(ClipboardService);
  elementRef = inject(ElementRef);
  private _copyIcon!: HTMLElement;
  renderer = inject(Renderer2);
  text = input<SafeAny>(null, { alias: 'appCopy' });
  classNames = input<string>(
    'cursor-pointer text-secondary-70 Caption copyable flex items-center gap-x-2',
  );
  ngOnInit(): void {
    this.classNames()
      .split(' ')
      .forEach((className) => this.renderer.addClass(this.elementRef.nativeElement, className));

    // Create the FontAwesome icon element
    this._copyIcon = this.renderer.createElement('span');
    this.renderer.setAttribute(this._copyIcon, 'class', 'fa-regular fa-copy');
    // Append the icon to the host element
    this.renderer.appendChild(this.elementRef.nativeElement, this._copyIcon);
  }

  @HostListener('click', ['$event'])
  async onClick(event: Event): Promise<void> {
    event.stopPropagation();

    const textToCopy = this.text();

    await this.clipboardService.copyText(textToCopy);
  }
}
