<form [formGroup]="form">
  <mat-form-field class="w-full" [formGroupName]="innerFormGroupName()!">
    @if (placeholder()) {
      <mat-label>{{ placeholder() }}</mat-label>
    }
    <mat-select class="app-mat-mdc-select" [formControlName]='controlName()'>
      @if (showNoValueOption()) {
        <mat-option>-- None --</mat-option>
      }
        @for (group of OptionGroups(); track group) {
          <mat-optgroup [label]="group.name!"
                        [disabled]="group.disabled"
                        [ngClass]="{ 'hide-group-label': !group.name }">
            @for (option of group.options; track option) {
              <mat-option
                [ngClass]="{ 'non-clickable': group.hasAction}"
                [value]="option.value"
                [disabled]='option.disabled'
                (onSelectionChange)="onSelectionChange($event,option)"
              >
                <div>
                  @if (option.icon) {
                    <img [src]="option.icon" alt="{{ option.value }} icon"
                         class="h-[18px] w-[18px] mr-2 text-[var(--c-primary-60)]">
                  }
                  {{ option.viewValue }}
                </div>
                @if (group.hasAction) {
                  <button class="pointer-events-auto" mat-icon-button (click)="actionClick($event, option)">
                    <mat-icon class="plus-icon-color">add</mat-icon>
                  </button>
                }
              </mat-option>
            }
          </mat-optgroup>
        }
    </mat-select>
  </mat-form-field>
</form>
