import { Injectable, signal, computed, inject } from '@angular/core';
import { ApiResponse, ExpertUserInfo } from '@core/models';
import { map, Observable, tap } from 'rxjs';
import { HttpService } from './http.service';
import { ROUTES } from '@core/constants';
import { PermissionCode } from '@core/enums';
import { expertInfo } from '@core/signals';

@Injectable()
export class PermissionService {
  // Private fields
  private readonly httpService = inject(HttpService);
  private readonly permissions = signal<Set<PermissionCode>>(new Set());

  // Public signals
  readonly userPermissions = computed(() => Array.from(this.permissions()));

  /**
   * Sets user permissions from Permission array
   * Filters selected permissions and converts to Set for O(1) lookup
   */
  setUserPermissions(permissions: PermissionCode[]): void {
    const selectedPermissions = new Set(permissions);

    this.permissions.set(selectedPermissions);
  }

  /**
   * Checks if user has specific permission
   * No permission required if permission is undefined
   */
  hasPermission(permission?: PermissionCode): boolean {
    return !permission || this.permissions().has(permission);
  }

  /**
   * Checks if user has any of the specified permissions
   * No permissions required if permissions array is empty/undefined
   */
  hasAnyPermission(permissions?: PermissionCode[]): boolean {
    if (!permissions?.length) return true;

    return permissions.some((permission) => this.permissions().has(permission));
  }

  /**
   * Checks if user has all specified permissions
   * No permissions required if permissions array is empty/undefined
   */
  hasAllPermissions(permissions?: PermissionCode[]): boolean {
    if (!permissions?.length) return true;

    return permissions.every((permission) => this.permissions().has(permission));
  }

  /**
   * Clears all permissions
   */
  resetPermissions(): void {
    this.permissions.set(new Set());
  }

  /**
   * Loads user permissions from API
   * Returns void Observable for completion handling
   */
  loadUserPermissions(): Observable<void> {
    return this.httpService.get<ApiResponse<ExpertUserInfo>>(ROUTES.auth.userInfo).pipe(
      tap((response) => expertInfo.set(response.data)),
      map((response) => response.data.modules),
      map((modules) => {
        this.setUserPermissions(modules);
      }),
    );
  }
}
