import { Pipe, PipeTransform } from '@angular/core';
import { TruncatePostion } from '@core/types';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  /**
   * Truncates a string based on maximum length and position.
   * Returns the original value if it's null, undefined, empty, or shorter than maxLength.
   *
   * @param value - The string to truncate (can be null or undefined)
   * @param maxLength - Maximum length of the resulting string (default: 15)
   * @param position - Position to truncate: 'beginning', 'middle', or 'end' (default: 'middle')
   * @returns The truncated string or the original value if no truncation is needed
   */
  transform(
    value: string | null | undefined,
    maxLength = 15,
    position: TruncatePostion = 'middle',
  ): string | null | undefined {
    // Return original value if it's null, undefined, empty, or shorter than maxLength
    if (!value || typeof value !== 'string' || value.length <= maxLength) {
      return value;
    }

    if (position === 'beginning') {
      return `...${value.slice(-maxLength)}`;
    } else if (position === 'middle') {
      const start = value.slice(0, Math.floor((maxLength - 3) / 2));
      const end = value.slice(-Math.floor((maxLength - 3) / 2));
      return `${start}...${end}`;
    } else {
      // 'end'
      return `${value.slice(0, maxLength - 3)}...`;
    }
  }
}
