import { inject, Pipe, PipeTransform } from '@angular/core';
import { MASK_CONFIG } from '@core/configs';

@Pipe({
  name: 'trimTrailingZeros',
  standalone: true,
})
export class TrimTrailingZerosPipe implements PipeTransform {
  maskConfig = inject(MASK_CONFIG);

  transform(value: string | number, precision?: number): number | string {
    // Convert to number to handle various input types
    const num = typeof value === 'string' ? parseFloat(value) : value;

    // Check if the number is valid
    if (isNaN(num)) return '';

    // Use toFixed to set precision, then remove unnecessary zeros
    const fixedNum = num.toFixed(precision ?? this.maskConfig.precision);

    // Regex to remove trailing zeros after the decimal point
    return parseFloat(fixedNum);
  }
}
