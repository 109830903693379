import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  template: `
    <button mat-icon-button>
      <span class="fa-regular fa-bell"></span>
    </button>
  `,
  styles: ``,
})
export class NotificationsComponent {}
