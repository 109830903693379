import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '@core/types';
import { HttpRequestState, wrapWithinHttpRequestState } from '@core/utils';
import { Observable } from 'rxjs';

@Pipe({
  name: 'withLoading',
  standalone: true,
})
export class WithLoadingPipe implements PipeTransform {
  transform(val: SafeAny): Observable<HttpRequestState<SafeAny>> {
    return wrapWithinHttpRequestState(val);
  }
}
