import { booleanAttribute, Component, inject, input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent,
  MatChipRemove,
  MatChipRow,
} from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SafeAny } from '@core/types';
import { getInputFirstErrorMessage } from '@core/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mat-chip-input',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatChipInput,
    MatChipRow,
    MatChipGrid,
    MatChipRemove,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './mat-chip-input.component.html',
  styleUrl: './mat-chip-input.component.scss',
})
export class MatChipInputComponent implements OnInit, OnDestroy {
  // Inputs
  label = input('Items');
  addOnBlur = input(true, {
    transform: booleanAttribute,
  });
  separatorKeysCodes = input([13, 188, 32]);
  placeholder = input('New Item...');

  items: SafeAny[] = [];
  formGroup = inject(FormGroupDirective);
  controlName = input.required<string>();

  // Subscription management
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.initializeFormControl();
    this.setupFormResetListener();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeFormControl(): void {
    // Initialize items from the form control value
    const initialValue = this.control?.value;
    if (Array.isArray(initialValue)) {
      this.items = [...initialValue];
    }
    this.updateFormControlValue();
  }

  private setupFormResetListener(): void {
    this.formGroup.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.formGroup.form.pristine),
      )
      .subscribe(() => {
        // Reset items when form becomes pristine
        this.items = this.control?.value || [];
      });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.items.includes(value)) {
      this.items.push(value);
      this.updateFormControlValue();
    }
    event.chipInput!.clear();
  }

  remove(item: string): void {
    const index = this.items.findIndex((i) => i === item);
    if (index >= 0) {
      this.items.splice(index, 1);
      this.updateFormControlValue();
    }
  }

  private updateFormControlValue(): void {
    if (this.control) {
      // Use spread to create a new array reference
      this.control.setValue([...this.items]);
    }
  }

  get control(): FormControl {
    return this.formGroup.form.get(this.controlName()) as FormControl;
  }

  get errorMessage(): string {
    if (this.control?.errors) {
      return getInputFirstErrorMessage(this.control.errors).message;
    }
    return '';
  }
}
