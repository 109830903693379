import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean',
  standalone: true,
})
export class BooleanPipe implements PipeTransform {
  /**
   * Transforms a value into customizable text based on its truthiness.
   *
   * @param value - The value to be transformed (can be boolean, string, null, undefined, or any other type)
   * @param trueLabel - The text to display when the value is truthy (default is 'Yes')
   * @param falseLabel - The text to display when the value is falsy (default is 'No')
   * @returns A string representing the value's truthiness based on the provided labels
   *
   * Truthy values include:
   * - true
   * - Non-empty strings (except 'false', '0', 'null', 'undefined')
   * - Numbers other than 0
   * - Objects and Arrays
   *
   * Falsy values include:
   * - false
   * - null
   * - undefined
   * - Empty string ''
   * - 0
   * - NaN
   * - Strings: 'false', '0', 'null', 'undefined' (case-insensitive)
   *
   * Example usage:
   * {{ someValue | boolean }}  // Outputs 'Yes' or 'No'
   * {{ someValue | boolean:'Enabled':'Disabled' }}  // Outputs 'Enabled' or 'Disabled'
   * {{ nullValue | boolean:'Active':'Inactive' }}  // Outputs 'Inactive'
   * {{ 'true' | boolean:'On':'Off' }}  // Outputs 'On'
   */
  transform(value: unknown, trueLabel = 'Yes', falseLabel = 'No'): string {
    if (value === null || value === undefined) {
      return falseLabel;
    }

    if (typeof value === 'string') {
      const lowercaseValue = value.toLowerCase().trim();
      if (
        lowercaseValue === '' ||
        lowercaseValue === 'false' ||
        lowercaseValue === '0' ||
        lowercaseValue === 'null' ||
        lowercaseValue === 'undefined'
      ) {
        return falseLabel;
      }
    }

    if (typeof value === 'number') {
      if (value === 0 || Number.isNaN(value)) {
        return falseLabel;
      }
    }

    return value ? trueLabel : falseLabel;
  }
}
