import { RegexKey } from '@core/enums';
import { ValidationError, ValidationErrorParams } from '@core/interfaces';

export const REGEXES: Record<RegexKey, RegExp> = {
  [RegexKey.Email]: /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  [RegexKey.Password]:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
  [RegexKey.Ip]:
    /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/,
  [RegexKey.Url]:
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
};
export const VALIDATION_ERROR_MESSAGES: Record<
  string,
  (params: ValidationErrorParams) => ValidationError
> = {
  required: (_) => ({ message: 'This field is required.' }),
  maxLength: (params) => ({ message: `The input cannot exceed ${params.maxLength} characters.` }),
  minLength: (params) => ({ message: `At least ${params.minLength} characters are required.` }),
  min: (params) => ({ message: `Value must be at least ${params.min}.` }),
  max: (params) => ({ message: `Value must be at most ${params.max}.` }),
  pattern: (params) => ({ message: `Invalid ${params.label} format.` }),
  mismatchPassword: (_) => ({ message: 'Passwords do not match.' }),
  invalidCardPrefixNumber: (_) => ({
    message: 'Invalid entry. The prefix must be a 6-digit number.',
  }),
  invalidIpAddress: (_) => ({
    message: 'Invalid IP address. Please enter a valid IPv4.',
  }),
  invalidUrl: (_) => ({
    message: 'Invalid URL address. Please enter a valid URL.',
  }),
  gratherThan: (_) => ({ message: 'Max value must be greater than the min value.' }),
  gratherThanOrEqual: (_) => ({
    message: 'Max value must be greater than or equal the min value.',
  }),
  integer: (_) => ({ message: 'Enter a valid integer number.' }),
  invalidOption: (_) => ({ message: 'Please select a valid item from the list' }),
};
