import { inject, Injectable } from '@angular/core';
import { AppSnackBarService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  appSnackBarService = inject(AppSnackBarService);

  async copyText(text: string): Promise<void> {
    if (!text) {
      this.appSnackBarService.warning('Enter some text to copy.');
      return;
    }

    try {
      if (navigator.permissions) {
        const permissionStatus = await navigator.permissions.query({
          name: 'clipboard-write' as PermissionName,
        });
        if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
          await this.writeToClipboard(text);
        } else {
          this.appSnackBarService.error('Clipboard permission denied');
        }
      } else {
        await this.writeToClipboard(text);
      }
    } catch {
      // fallback block
      // If Permissions API is not supported, try to write to the clipboard directly
      await this.writeToClipboard(text); // Attempt direct copy as fallback
      this.appSnackBarService.error(
        'Error accessing clipboard permissions (writing directly to the clipboard without permission check).',
      );
    }
  }

  private async writeToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      this.appSnackBarService.success('Copied to clipboard!');
    } catch {
      this.appSnackBarService.error('Error writing to clipboard');
    }
  }
}
