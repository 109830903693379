import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-logo-name-td',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltip],
  templateUrl: './logo-name-td.component.html',
  styleUrl: './logo-name-td.component.scss',
})
export class LogoNameTdComponent {
  logoUrl = input.required<string>();
  name = input.required<string>();

  protected hasImageError = false;
}
