import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { THEME_KEY } from '@core/constants';
import { ColorModesEnum } from '@core/enums';

@Injectable()
export class ThemeService {
  private readonly localStorageKey = THEME_KEY;
  private readonly colorModes = ColorModesEnum;

  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private isLightMode = this.document.body.classList.contains(this.colorModes.LIGHT_MODE);

  get currentTheme(): ColorModesEnum {
    return this.isLightMode ? this.colorModes.LIGHT_MODE : this.colorModes.DARK_MODE;
  }

  syncWithUserPreferedTheme(): void {
    if (isPlatformBrowser(this.platformId)) {
      const prefersLightScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const storedMode = localStorage.getItem(this.localStorageKey);
      this.isLightMode = storedMode
        ? storedMode === this.colorModes.LIGHT_MODE
        : prefersLightScheme;

      this.applyTheme(this.colorModes.LIGHT_MODE);
    }
  }

  setMode(newMode: ColorModesEnum): void {
    this.isLightMode = newMode === this.colorModes.LIGHT_MODE;
    this.applyTheme(newMode);
  }

  toggleMode(): void {
    this.isLightMode = !this.isLightMode;
    this.applyTheme(this.isLightMode ? this.colorModes.LIGHT_MODE : this.colorModes.DARK_MODE);
  }

  private applyTheme(mode: ColorModesEnum): void {
    const oppositeMode =
      mode === this.colorModes.LIGHT_MODE ? this.colorModes.DARK_MODE : this.colorModes.LIGHT_MODE;

    this.document.body.classList.remove(oppositeMode);
    this.document.body.classList.add(mode);
    localStorage.setItem(this.localStorageKey, mode);
  }
}
