import { AfterViewInit, Component, inject, OnDestroy } from '@angular/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { ValidationError } from '@core/interfaces';
import { SafeAny } from '@core/types';
import { getInputFirstErrorMessage } from '@core/utils';
import { ValidSubmitDirective } from '@shared/directives';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-error[appMatErrorMessages]',
  template: '{{ error.message }}',
  standalone: true,
  imports: [],
})
export class MatErrorMessagesComponent implements AfterViewInit, OnDestroy {
  public error: ValidationError = { message: '' };
  private inputRef!: MatFormFieldControl<unknown>;
  private container = inject(MatFormField);
  private subscriptions: Subscription[] = [];
  private formSumitter = inject(ValidSubmitDirective, { optional: true });
  public ngAfterViewInit(): void {
    this.inputRef = this.container._control;

    if (this.formSumitter) {
      this.subscriptions.push(
        this.formSumitter.formGroupDirective.ngSubmit.subscribe(() => {
          this.validateInput();
        }),
      );
    }

    if (this.inputRef && this.inputRef.ngControl) {
      this.subscriptions.push(
        this.inputRef.ngControl.statusChanges!.subscribe(() => {
          this.updateErrors();
        }),
      );
    }
    const nativeElement = (this.inputRef as SafeAny)._elementRef?.nativeElement;
    if (nativeElement) {
      this.subscriptions.push(
        fromEvent(nativeElement, 'blur').subscribe(() => {
          this.validateInput();
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private validateInput(): void {
    if (this.inputRef && this.inputRef.ngControl && this.inputRef.ngControl.invalid) {
      this.inputRef.ngControl.control?.markAsTouched();
      this.inputRef.ngControl.control?.updateValueAndValidity();
      this.updateErrors();
    }
  }

  private updateErrors = (): void => {
    this.error = getInputFirstErrorMessage(this.inputRef!.ngControl!.errors);
  };
}
