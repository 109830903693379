import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  template: `
    <button mat-icon-button>
      <span class="fa-regular fa-user"></span>
    </button>
  `,
  styles: ``,
})
export class ProfileComponent {}
