import { KYCStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';

export function getKycStatus(kycStatus: KYCStatusEnum): VerificationStatus {
  const statusMap: Record<KYCStatusEnum, VerificationStatus> = {
    [KYCStatusEnum.Rejected]: { text: 'Rejected', color: 'red', icon: 'fa-solid fa-xmark' },
    [KYCStatusEnum.Waiting]: {
      text: 'Waiting',
      color: 'yellow',
      icon: 'fa-solid fa-hourglass-half',
    },
    [KYCStatusEnum.Verified]: { text: 'Verified', color: 'green', icon: 'fa-solid fa-check' },
    [KYCStatusEnum.UnVerified]: { text: 'Unverified', color: 'red', icon: 'fa-solid fa-xmark' },
  };

  return statusMap[kycStatus];
}
