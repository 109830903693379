import { PermissionCode } from '@core/enums';
import { NavItem } from '@shared/layout';

/**
 * Recursively merges permissions from a navigation item and its children.
 * If any child has no permissions, it stops further recursion and returns an
 * empty array to indicate that the parent menu should be shown regardless of permissions.
 *
 * @param {NavItem} navItem - The navigation item whose permissions need to be merged.
 * @returns {PermissionCode[]} - A unique array of permissions, or an empty array
 *                               if any child has no permissions.
 */
export function mergePermissions(navItem: NavItem): PermissionCode[] {
  let permissions: PermissionCode[] = [];

  // Add the current nav item's permissions if they exist
  if (navItem.permissions) {
    permissions = permissions.concat(navItem.permissions);
  }

  // Recursively add permissions from child navigation items, if necessary
  if (navItem.children) {
    for (const child of navItem.children) {
      const childPermissions = mergePermissions(child);

      // If a child has no permissions, stop recursion and indicate parent menu should show
      if (childPermissions.length === 0) {
        return []; // Allow parent menu to be shown unconditionally
      }

      permissions = permissions.concat(childPermissions);
    }
  }

  // Remove duplicate permissions by converting to a Set and back to an array
  return [...new Set(permissions)];
}

/**
 * Generates a record of routes and their corresponding permissions from a list of navigation items.
 *
 * @param {NavItem[]} items - The list of navigation items.
 * @returns {Record<string, PermissionCode[]>} - A record where keys are route paths and values are arrays of permissions.
 */
export function generateRoutePermissions(items: NavItem[]): Record<string, PermissionCode[]> {
  const permissions: Record<string, PermissionCode[]> = {};

  for (const item of items) {
    const routePath = item.link; // Current route path

    // Store the permissions for the current route
    permissions[routePath] = item.permissions || [];

    // Recursively process child routes and merge their permissions
    if (item.children) {
      Object.assign(permissions, generateRoutePermissions(item.children));
    }
  }

  return permissions;
}
