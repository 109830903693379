import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'app-active-status-td',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIconButton],
  templateUrl: './active-status-td.component.html',
  styleUrl: './active-status-td.component.scss',
})
export class ActiveStatusTdComponent {
  status = input.required<boolean>();
  activeIcon = input('fa-check');
  inactiveIcon = input('fa-xmark');
  activeColor = input('text-green-base');
  inactiveColor = input('text-red-base');

  get statusIcon(): string {
    return this.status() ? this.activeIcon() : this.inactiveIcon();
  }

  get statusColorClass(): string {
    return this.status() ? this.activeColor() : this.inactiveColor();
  }
}
