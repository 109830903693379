import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '@core/types';

@Pipe({
  name: 'enumLabel',
  standalone: true,
})
export class EnumLabelPipe implements PipeTransform {
  transform(value: number, enumType: SafeAny): string {
    return enumType[value] || 'Invalid Enum Value';
  }
}
