import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appTrimInput]',
  standalone: true,
})
export class TrimInputDirective {
  private elementRef = inject(ElementRef);
  private ngControl = inject(NgControl, { optional: true });

  @HostListener('input')
  onInput() {
    const trimmedValue = this.elementRef.nativeElement.value.trim();
    this.elementRef.nativeElement.value = trimmedValue;

    if (this.ngControl && this.ngControl.control) {
      // Update the form control value
      this.ngControl.control.setValue(trimmedValue, { emitEvent: false });
    }
  }
}
