import { InjectionToken } from '@angular/core';
import { MaskConfig } from '@core/utils';
const PRECISION = 8;
const DIGIT_LIMIT = 15;
export const DEFAULT_MASK_CONFIG: MaskConfig = {
  precision: PRECISION,
  mask: `separator.${PRECISION}`,
  dropSpecialCharacters: true,
  separatorLimit: '9'.repeat(DIGIT_LIMIT),
  digitLimit: DIGIT_LIMIT,
  allowNegativeNumbers: false,
  thousandSeparator: ',',
  decimalMarker: '.',
};

export const MASK_CONFIG = new InjectionToken<MaskConfig>('Mask configuration token');
