import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { NullishReplacementPipe } from '@shared/pipes';

@Component({
  selector: 'app-date-time-td',
  standalone: true,
  imports: [DatePipe, NullishReplacementPipe],
  templateUrl: './date-time-td.component.html',
  styleUrl: './date-time-td.component.scss',
})
export class DateTimeTdComponent {
  dateTime = input.required<string>();
}
