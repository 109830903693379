import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncActionableSelectorInterface, BaseOptionType } from '@core/interfaces';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SafeAny } from '@core/types';

@Component({
  selector: 'app-actionable-selector',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgClass,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './actionable-selector.component.html',
  styleUrl: './actionable-selector.component.scss',
})
export class ActionableSelectorComponent implements OnInit {
  controlName = input.required<string>();
  innerFormGroupName = input<string | undefined>();
  OptionGroups = input<AsyncActionableSelectorInterface[] | null>();
  placeholder = input<string | undefined>();
  showNoValueOption = input<boolean>(false);
  onOptionClick = output();
  private _rootFormGroup = inject(FormGroupDirective);
  onActionClick = output<string>();
  form!: FormGroup;

  constructor() {
    computed(() => {
      if (!this.OptionGroups()) {
        this.form.get(this.controlName())?.disable();
        return;
      }
      this.form.get(this.controlName())?.enable();
    });
  }

  ngOnInit() {
    this.form = this._rootFormGroup.control as FormGroup;
  }

  actionClick(event: Event, option: BaseOptionType): void {
    event.stopPropagation();
    this.onActionClick.emit(option.value);
  }

  onSelectionChange(event: MatOptionSelectionChange, option: SafeAny) {
    if (event.isUserInput) {
      this.onOptionClick.emit(option);
    }
  }
}
