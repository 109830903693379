import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@core/models';
import { SafeAny } from '@core/types';

@Pipe({
  name: 'enumToSelectOptions',
  standalone: true,
})
export class EnumToSelectOptionsPipe implements PipeTransform {
  transform(enumObject: SafeAny, splitOnUppercase = true): SelectOption[] {
    return Object.keys(enumObject)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        label: this.formatLabel(key, splitOnUppercase),
        value: enumObject[key],
      }));
  }

  private formatLabel(label: string, splitOnUppercase: boolean): string {
    let formattedLabel = label;

    // Conditionally add space before uppercase letters if splitOnUppercase is true
    if (splitOnUppercase) {
      formattedLabel = formattedLabel.replace(/([A-Z])/g, ' $1');
    }

    return formattedLabel
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .trim();
  }
}
