<mat-form-field [class.has-chips]="items.length > 0">
  <mat-label>{{ label() }}</mat-label>
  <div class="app-chip-grid-container">
    <mat-chip-grid #chipGrid [attr.aria-label]="'Enter' + label()" [formControl]="control">
      @for (item of items; track item) {
        <mat-chip-row (removed)="remove(item)">
          {{ item }}
          <button matChipRemove [attr.aria-label]="'remove ' + item">
            <span class="fa-solid fa-xmark"></span>
          </button>
        </mat-chip-row>
      }
      <input
        [placeholder]="placeholder()"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes()"
        [matChipInputAddOnBlur]="addOnBlur()"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </div>
  <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>
