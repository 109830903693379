import { NgClass } from '@angular/common';
import { Component, input, model, effect, booleanAttribute } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatPrefix,
    MatSuffix,
    MatIconButton,
    NgClass,
  ],
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.scss',
})
export class SearchBoxComponent {
  label = input.required();
  searchQuery = model<string>('');
  rounded = input(false, { transform: booleanAttribute });
  size = input<'small' | 'default'>('small');
  prefix = input(false, { transform: booleanAttribute });
  suffix = input(true, { transform: booleanAttribute });
  debounceTime = input<number>(300); // Configurable debounce time in milliseconds

  private searchSubject = new Subject<string>();

  constructor() {
    // Set up the debounced search
    this.searchSubject
      .pipe(debounceTime(this.debounceTime()), distinctUntilChanged())
      .subscribe((value) => {
        this.searchQuery.set(value);
      });

    // Clean up the subject when the component is destroyed
    effect(() => {
      return () => {
        this.searchSubject.complete();
      };
    });
  }

  onInputChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchSubject.next(value);
  }

  clearSearch() {
    this.searchQuery.set('');
    this.searchSubject.next('');
  }
}
