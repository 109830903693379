import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  inject,
  input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatOption,
} from '@angular/material/autocomplete';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutocompleteConfig } from '@core/components';
import { SafeAny } from '@core/types';
import { MatErrorMessagesComponent } from '@shared/components';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    MatAutocompleteModule,
    MatError,
    MatErrorMessagesComponent,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    NgTemplateOutlet,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './autocomplete.component.html',
  styleUrl: 'autocomplete.component.scss',
})
export class AutocompleteComponent<T> implements OnInit, OnDestroy {
  label = input('Autocomplete');
  placeholder = input('');
  optionTemplate = contentChild<TemplateRef<T>>(TemplateRef);
  config = input.required<AutocompleteConfig<T>>();
  controlName = input.required<string>();

  formGroup = inject(FormGroupDirective);
  options: T[] = [];

  filteredOptions$!: Observable<T[]>;
  private _destroy$ = new Subject<void>();

  ngOnInit() {
    this._loadData()
      .pipe(
        catchError((error) => {
          console.error('Fetch error:', error);
          return of([]);
        }),
      )
      .subscribe((data) => {
        this.options = [...data];
        this.control.addValidators(this.optionValidator());
        this.filteredOptions$ = this._refineOptions();
      });
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    const selectedItem = this.options.find((option) => this._display(option) === selectedValue);

    if (selectedItem) {
      this.control.setValue(selectedItem);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get control() {
    return this.formGroup.form.get(this.controlName()) as FormControl;
  }

  get _loadData() {
    return this.config().fetchItems;
  }
  /**
   * Listens to control value changes and filters options based on the input.
   * Uses debouncing to limit the frequency of filtering and ensures cleanup on component destruction.
   *
   * @private
   * @returns Observable of the filtered options
   */
  private _refineOptions() {
    return this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      takeUntil(this._destroy$),
      map((value) => {
        const searchValue = this._normalizeValue(value);
        return this._filter(searchValue);
      }),
    );
  }
  get _display() {
    // Use the displayFn from config, or fallback to toString
    return this.config().displayItem || ((item: T) => item?.toString() || '');
  }
  /**
   * Normalize value for filtering
   */
  private _normalizeValue(value: SafeAny): string {
    if (value == null) return '';

    if (typeof value === 'object') {
      return this._display(value);
    }

    return value.toString();
  }
  /**
   * Custom validator to check if the entered/selected value exists in options
   */
  private optionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      if (this.options.length === 0) return null;

      const isValidOption = this.options.some(
        (option) => this._display(option) === this._normalizeValue(control.value),
      );

      if (!isValidOption && this.config().strictValidation) return { invalidOption: true };
      if (typeof control.value !== 'object') {
        const matchedOption = this.options.find(
          (option) => this._display(option) === this._normalizeValue(control.value),
        );

        if (matchedOption) {
          this.control.setValue(matchedOption, { emitEvent: false, onlySelf: false });
        }
      }

      return null;
    };
  }
  private _filter(value: string): T[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      this._display(option).toLowerCase().includes(filterValue),
    );
  }
}
