import { Directive, HostListener, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { resetFormGroup } from 'app/core/utils/form';

@Directive({
  selector: '[appFormReset]',
  standalone: true,
})
export class FormResetDirective {
  formGroup = input.required<FormGroup>({ alias: 'appFormReset' });

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    if (this.formGroup()) {
      resetFormGroup(this.formGroup());
    }
  }
}
