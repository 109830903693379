import { Directive, HostListener, inject } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { SafeAny } from '@core/types';

@Directive({
  selector: '[appDateTimeFormat]',
  standalone: true,
})
export class DateTimeFormatDirective {
  ngControl = inject(FormControlName);
  @HostListener('dateTimeChange', ['$event'])
  onDateTimeChange(event: SafeAny) {
    if (event && event.value) {
      const date = new Date(event.value);
      const formattedDate = date.toISOString();
      if (this.ngControl.control) {
        this.ngControl.control.setValue(formattedDate);
      }
    }
  }
}
