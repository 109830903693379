import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ColorModesEnum } from '@core/enums';
import { AuthService, ThemeService } from '@core/services';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileComponent } from './profile/profile.component';

@Component({
  selector: 'app-page-top-toolbar',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ProfileComponent, NotificationsComponent, MatButtonModule, MatIconModule],
  template: `
    <app-profile />
    <app-notifications />
    <button mat-icon-button (click)="onLogout()">
      <span class="fa-regular fa-arrow-right-from-bracket"></span>
    </button>
  `,
  styles: [
    `
      app-page-top-toolbar {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        .mdc-icon-button {
          color: var(--c-secondary-70);
        }
      }
    `,
  ],
})
export class PageTopToolbarComponent {
  router = inject(Router);
  themeService = inject(ThemeService);
  authService = inject(AuthService);
  ColorModesEnum = ColorModesEnum;
  toggleTheme() {
    this.themeService.toggleMode();
  }
  onLogout(): void {
    this.authService.logoutFromServer();
  }
}
