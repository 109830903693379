<div class="flex flex-col gap-1">
  <span>{{ user().firstName | nullish }} {{ user().lastName | nullish }}</span>
  @if (enableCopy()) {
    <span [appCopy]="user().email">
      {{ (enableTruncate() ? (user().email | truncate: 20) : user().email) | nullish }}
    </span>
  } @else {
    <span class="Caption text-secondary-70">
      {{ (enableTruncate() ? (user().email | truncate: 20) : user().email) | nullish }}
    </span>
  }
</div>
