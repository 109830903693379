import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinAndUppercase',
  standalone: true,
})
export class JoinAndUppercasePipe implements PipeTransform {
  transform(value: string[]): string {
    if (!Array.isArray(value)) {
      return '';
    }

    return value.map((item) => item.toUpperCase()).join(', ');
  }
}
