import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-password-toggler',
  templateUrl: './password-toggler.component.html',
  styleUrl: 'password-toggler.component.scss',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatFormFieldModule, MatInputModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordTogglerComponent {
  inputElement = input.required<HTMLInputElement>();
  visible = false;

  toggleVisibility() {
    this.visible = !this.visible;
    this.inputElement().type = this.visible ? 'text' : 'password';
  }
}
