import { Directive, HostListener, output } from '@angular/core';

@Directive({
  selector: '[appSecretKeyGenerator]',
  standalone: true,
})
export class SecretKeyGeneratorDirective {
  onSecretKeyGenerated = output<string>();

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    event.preventDefault();
    const secretKey = crypto.randomUUID();
    this.onSecretKeyGenerated.emit(secretKey);
  }
}
