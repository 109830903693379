<div class="upload-box">
  <p class="Body-1 text-secondary-60 upload-box__label">
    {{ label() }}
    @if (required()) {
      <span class="text-error-60">*</span>
    }
  </p>
  @if (!attachment && !selectedFile) {
    <div
      class="upload-box__file dropzone"
      (click)="chooseFile()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [ngClass]="{
        'drag-over': isDragOver,
        'mat-mdc-form-field-error': ngControl.invalid && submitted,
      }"
    >
      <span class="fal fa-folder-open upload-box__folder-icon"></span>
      <span class="upload-box__drag-file-text Body-3">{{ dragDropMessage() }}</span>
      <span class="upload-box__choose-file-text Subtitle-1">{{ chooseFileMessage() }}</span>
    </div>
  }
  @if (attachment || selectedFile) {
    <div
      class="upload-box__file-preview"
      [ngClass]="{ 'has-error': hasError }"
      (click)="chooseFile()"
    >
      <span class="upload-box__file-viewer">
        @if (uploading) {
          <span class="fa-regular fa-spinner-third fa-spin file-icon"></span>
        } @else {
          @if (hasFile && !hasError) {
            @if (isImage) {
              <img [src]="filePreview" alt="File preview" />
            }
          } @else {
            <span class="fa-regular fa-file file-icon"></span>
          }
        }
      </span>
      <span class="upload-box__file-info">
        <span class="Body-3 upload-box__file-name">
          {{ fileInfo!.name | truncate }}
        </span>
        @if (hasError) {
          <span class="upload-box__file-error text-secondary-70 Overline">{{ errorMessage }}</span>
        } @else {
          <span class="upload-box__file-size text-secondary-70 Overline">{{
            fileInfo!.size | fileSize
          }}</span>
        }
      </span>
      <span class="upload-box__actions">
        @if (!hasError) {
          @if (uploaded) {
            <span
              class="fa-regular fa-download text-surface-60"
              appDownloadFile
              [fileUrl]="filePreview"
            ></span>
          } @else {
            <span
              class="fa-regular fa-upload text-surface-60"
              (click)="uploadManually($event)"
            ></span>
          }
        }
        @if (hasError) {
          <span class="fa-solid fa-xmark text-surface-60" (click)="removeFile($event)"></span>
        } @else {
          <span class="fa-regular fa-trash-can text-surface-60" (click)="removeFile($event)"></span>
        }
      </span>
    </div>
  }
  <div class="upload-box__hint Overline text-secondary-60 mt-s">
    Up To {{ maxFileSize() | fileSize }} -
    <span>
      {{ acceptedFormats() | joinAndUppercase }}
    </span>
  </div>
</div>
<input
  type="file"
  #fileInput
  class="file-input"
  (change)="onFileSelected($event)"
  [accept]="acceptedFormats().join(',')"
/>
