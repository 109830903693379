export enum PermissionCode {
  BankVerify = 1,
  KycRequests,
  Currency,
  BankManagement,
  KycProvider,
  Experts,
  UpdateRate,
  Report,
  WalletManagement,
  Customers,
  Withdraw,
  Deposit,
  PaymentProvider,
  PaymentMethod,
  PartnerManagement,
}
