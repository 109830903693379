import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES, USER_CREADENTIALS_STORAGE_KEY } from '@core/constants';
import { ApiResponse, JWT } from '@core/models';
import { jwtCredentials, refreshingToken } from '@core/signals';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { Credentials, LoginDto } from '@core/dtos';
import { EncryptionService } from './encryption.service';
import { PermissionService } from './permission.service';

@Injectable()
export class AuthService {
  private _router = inject(Router);
  private _httpService = inject(HttpService);
  private _encryptionService = inject(EncryptionService);
  private _permissionsService = inject(PermissionService);
  private _activatedRoute = inject(ActivatedRoute);

  login(loginCredentials: LoginDto): Observable<JWT | null> {
    return this._httpService.post<ApiResponse<JWT>>(ROUTES.auth.login, loginCredentials).pipe(
      tap((credentials) => {
        this._saveCredentials({ ...credentials.data, ...loginCredentials });
      }),
      switchMap((response) => {
        return this._permissionsService.loadUserPermissions().pipe(map(() => response.data));
      }),
    );
  }

  refreshAccessToken(): Observable<JWT | null> {
    const refreshToken = jwtCredentials()?.refreshToken;
    if (!refreshToken) {
      this.logout();
      return throwError(() => new Error('Can not find valid refresh token'));
    }
    return this._httpService
      .post<ApiResponse<JWT>>(ROUTES.auth.refreshToken, { refreshToken: refreshToken })
      .pipe(
        map((response) => response.data),
        tap((credentials: JWT) => {
          jwtCredentials.set(credentials);
        }),
        catchError((error) => {
          this.logout(this._router.url);
          refreshingToken.set(false);
          return throwError(() => error);
        }),
      );
  }

  logout(returnUrl: string | undefined = undefined): void {
    const currentQueryParams = this._activatedRoute.snapshot.queryParams;
    if (currentQueryParams['returnUrl']) {
      returnUrl = currentQueryParams['returnUrl'];
    }
    if (returnUrl === '/login') returnUrl = undefined;

    const queryParams = returnUrl ? { returnUrl: returnUrl } : {};
    this._router.navigate(['/login'], { queryParams });
    this._clearCredentials();
  }

  logoutFromServer() {
    this._httpService.get<ApiResponse<unknown>>(ROUTES.auth.logout).subscribe();
    this.logout();
  }
  private _saveCredentials(credentials: Credentials) {
    jwtCredentials.set({
      accessToken: credentials.accessToken,
      refreshToken: credentials.refreshToken,
    });
    const encryptedCredentials = this._encryptionService.encrypt(JSON.stringify(credentials));
    localStorage.setItem(USER_CREADENTIALS_STORAGE_KEY, encryptedCredentials);
  }

  restoreCredentials() {
    const encryptedCredentials = localStorage.getItem(USER_CREADENTIALS_STORAGE_KEY);
    if (encryptedCredentials) {
      try {
        const decryptedCredentials = this._encryptionService.decrypt(encryptedCredentials);
        const credentials = JSON.parse(decryptedCredentials) as Credentials;
        return credentials;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_error) {
        this._clearCredentials();
      }
    }
    return null;
  }

  private _clearCredentials() {
    const credentials = this.restoreCredentials();
    if (credentials?.rememberMe) {
      credentials.accessToken = '';
      credentials.refreshToken = '';
      this._saveCredentials(credentials);
    } else {
      jwtCredentials.set(null);
      localStorage.removeItem(USER_CREADENTIALS_STORAGE_KEY);
    }
    this._permissionsService.resetPermissions();
  }
}
