import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit, effect, inject, input } from '@angular/core';
import { FormControl, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PasswordTogglerComponent } from '@shared/components';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mat-password-input',
  templateUrl: './mat-password-input.component.html',
  styleUrl: './mat-password-input.component.scss',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PasswordTogglerComponent,
    NgClass,
  ],
})
export class MatPasswordInputComponent implements OnInit, OnDestroy {
  controlName = input('password');

  conditions = [
    { text: 'At least 8 characters', met: false },
    { text: 'Includes uppercase letters', met: false },
    { text: 'Includes numbers or symbols', met: false },
    { text: 'Includes lowercase letters', met: false },
  ];

  private _subscription = new Subscription();
  disabled = input<boolean>();
  label = input<string>('Password');
  formGroup = inject(FormGroupDirective);

  constructor() {
    effect(() => {
      if (this.disabled()) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    });
  }
  ngOnInit() {
    this._subscription.add(
      this.control.valueChanges.subscribe((value) => {
        if (value !== null) {
          this.checkPasswordStrength(value);
        }
      }),
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  private checkPasswordStrength(password: string) {
    this.conditions[0].met = password.length >= 8;
    this.conditions[1].met = /[A-Z]/.test(password);
    this.conditions[2].met = /[0-9]|[!@#$%^&*(),.?":{}|<>]/.test(password);
    this.conditions[3].met = /[a-z]/.test(password);
  }
  get control() {
    return this.formGroup.form.get(this.controlName()!) as FormControl;
  }
}
