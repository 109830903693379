import { SafeAny } from '@core/types';

// type-utils.ts
type Primitive = string | number | boolean | null | undefined;

// Get nested path type
export type PathType<T, P extends SafeAny[]> = P extends [infer K, ...infer Rest]
  ? K extends keyof T
    ? Rest extends SafeAny[]
      ? PathType<T[K], Rest>
      : T[K]
    : never
  : T;

// Get all possible paths of an object type
export type ObjectPaths<T, Depth extends number = 3> = {
  [K in keyof T]: T[K] extends Primitive
    ? [K]
    : T[K] extends SafeAny[]
      ? [K]
      : T[K] extends object
        ? Depth extends 1
          ? [K]
          : [K] | [K, ...ObjectPaths<T[K], Depth extends number ? Subtract<Depth, 1> : never>]
        : [K];
}[keyof T];

// Helper type for subtracting numbers
type Subtract<N extends number, M extends number> = [...TupleOf<M>] extends [...infer U, SafeAny]
  ? TupleOf<N> extends [...U, infer R]
    ? R extends SafeAny[]
      ? R['length']
      : never
    : never
  : never;

type TupleOf<N extends number, T extends SafeAny[] = []> = T['length'] extends N
  ? T
  : TupleOf<N, [...T, SafeAny]>;

// search.utils.ts
export interface SearchConfig<T> {
  paths?: ObjectPaths<T>[]; // Type-safe paths
  caseSensitive?: boolean;
  matchMode?: 'includes' | 'startsWith' | 'endsWith' | 'equals';
}

export class SearchUtility<T extends object> {
  private getValueByPath<P extends ObjectPaths<T>>(obj: T, path: P): PathType<T, P> {
    return path.reduce(
      (current: SafeAny, key) =>
        current && typeof current === 'object' ? current[key] : undefined,
      obj,
    );
  }

  filter(items: T[], query: string, config: SearchConfig<T>): T[] {
    if (!query) return items;

    const normalizedQuery = config.caseSensitive ? query : query.toLowerCase();

    return items.filter((item) => {
      return config.paths?.some((path) => {
        const value = this.getValueByPath(item, path);
        if (value == null) return false;

        const stringValue = String(value);
        const normalizedValue = config.caseSensitive ? stringValue : stringValue.toLowerCase();

        switch (config.matchMode) {
          case 'startsWith':
            return normalizedValue.startsWith(normalizedQuery);
          case 'endsWith':
            return normalizedValue.endsWith(normalizedQuery);
          case 'equals':
            return normalizedValue === normalizedQuery;
          case 'includes':
          default:
            return normalizedValue.includes(normalizedQuery);
        }
      });
    });
  }
}

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

/**
 * Utility function to create a debounced search Observable with auto cleanup.
 *
 * @param debounceDuration The duration for debounce in milliseconds.
 * @returns An object with emitSearchQuery function, debouncedSearch$ Observable, and a destroy function.
 */
export function createDebouncedSearchQueryObject(debounceDuration = 300) {
  const searchQuery$ = new Subject<string>();
  const destroy$ = new Subject<void>();

  const debouncedSearch$ = searchQuery$.pipe(
    debounceTime(debounceDuration),
    distinctUntilChanged(),
    takeUntil(destroy$),
  );

  return {
    searchQuery: (query: string) => searchQuery$.next(query),
    debouncedSearch$,
    destroy: () => {
      destroy$.next();
      destroy$.complete();
    },
  };
}
