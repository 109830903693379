import { BankAccountVerificationStatusEnum } from '@core/enums';
import { VerificationStatus } from '@core/models';

export function getBankAccountVerificationRequestStatus(
  status: BankAccountVerificationStatusEnum,
): VerificationStatus {
  const statusMap: Record<BankAccountVerificationStatusEnum, VerificationStatus> = {
    [BankAccountVerificationStatusEnum.Accepted]: {
      text: 'Accepted',
      color: 'green-light',
      icon: 'fa-solid fa-check',
    },
    [BankAccountVerificationStatusEnum.Pending]: { text: 'Pending', color: 'yellow-light' },
    [BankAccountVerificationStatusEnum.Rejected]: {
      text: 'Rejected',
      color: 'red-light',
      icon: 'fa-solid fa-xmark',
    },
  };

  return statusMap[status];
}
