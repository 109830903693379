<div class="flex flex-col items-center justify-center h-full text-center mx-auto">
  @if (showImage()) {
    <img [src]="imageSrc()" alt="No data" class="object-contain mb-[32px] w-[100px] h-[100px]" />
  }

  @if (showTitle()) {
    <h2 class="Headline-6 text-secondary-10 mb-[16px] mt-0">
      {{ title() }}
    </h2>
  }

  @if (showDescription()) {
    <p class="Body-3 text-secondary-60 mt-0 px-l">
      {{ description() }}
    </p>
  }

  @if (showContent()) {
    <div class="w-[38rem] place-content-center flex flex-row space-x-4 mt-[12px]">
      <ng-content></ng-content>
    </div>
  }
</div>
