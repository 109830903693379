import { Component, input } from '@angular/core';

@Component({
  selector: 'app-no-data',
  standalone: true,
  imports: [],
  templateUrl: './no-data.component.html',
  styleUrl: './no-data.component.scss',
})
export class NoDataComponent {
  imageSrc = input('/assets/images/no-data-image.png');
  title = input('No Results Found');
  description = input(
    'Sorry, no items match your search criteria. Please modify your search and try again.',
  );
  showImage = input(true);
  showTitle = input(true);
  showDescription = input(true);
  showContent = input(true);
}
