@let inputSize = size() === 'small' ? 'app-small-input':'';
@let roundedClass = rounded() ? 'app-input-rounded':'';

<mat-form-field class="{{inputSize}} {{roundedClass}} w-full">
    <mat-label>{{label()}}</mat-label>
    @if(prefix()){
    <button matPrefix mat-icon-button aria-label="Clear" class="btn-small" type="button">
        <span class="fa-regular fa-magnifying-glass text-secondary-60"></span>
    </button>
    }
    <input matInput [ngModel]="searchQuery()" #searchInput (input)="onInputChange($event)">
    @if (searchInput.value) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()" class="btn-small" type="button">
        <span class="fa-solid fa-xmark text-secondary-60"></span>
    </button>
    }
</mat-form-field>