@let nav = navItem();
@if (!nav.children) {
<a class="nav-item mb-s px-m py-s Body-1" *appHasPermission="nav.permissions" [routerLink]="nav.link"
  routerLinkActive="active">
  <ng-container [ngTemplateOutlet]="navContent" [ngTemplateOutletContext]="{nav: nav, child:false}"></ng-container>
</a>
} @else {
<mat-expansion-panel [expanded]="shouldExpand(nav)" class="nav-item-menu mb-s" *appHasPermission="nav.permissions">

  <mat-expansion-panel-header class="px-m">
    <mat-panel-title class="Body-1">
      <ng-container [ngTemplateOutlet]="navContent" [ngTemplateOutletContext]="{nav: nav, child:false}"></ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-nav-list class="sub-menu">
    @for (subNav of nav.children; track subNav.id) {
    <a class="nav-item px-s mx-s py-s Body-1 child-nav-item" *appHasPermission="subNav.permissions"
      [routerLink]="subNav.link" routerLinkActive="active">
      <ng-container [ngTemplateOutlet]="navContent"
        [ngTemplateOutletContext]="{nav: subNav, child:true}"></ng-container>
    </a>
    }
  </mat-nav-list>
</mat-expansion-panel>
}

<ng-template #navContent let-nav="nav" let-child="child">
  <span class="nav-item-icon {{nav.icon}}" [ngClass]="{'child-nav':child}"></span>
  <span>{{ nav.label }}</span>
</ng-template>