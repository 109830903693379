import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  contentChild,
  effect,
  forwardRef,
  inject,
  input,
  model,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AsyncSelectorOptions, SafeAny } from '@core/types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectChange, MatSelectTrigger } from '@angular/material/select';
import { HttpService, QueryParamsMapperService } from '@core/services';
import { ApiResponse, PaginatedResponse, PaginationQueryParams } from '@core/models';
import { ScrollEndDirective } from '@shared/directives';
import { PAGINATION_ROWS_COUNTS } from '@core/constants';
import { HttpParams } from '@angular/common/http';
import { LoadStrategy } from '@core/enums';
import { MatButtonModule } from '@angular/material/button';
import { SearchBoxComponent } from '@shared/components';
import { MatDivider } from '@angular/material/divider';
import { NgTemplateOutlet } from '@angular/common';
@Component({
  selector: 'app-async-selector',
  templateUrl: './async-selector.component.html',
  styleUrl: './async-selector.component.scss',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelect,
    MatOption,
    ScrollEndDirective,
    MatSelectTrigger,
    MatButtonModule,
    FormsModule,
    SearchBoxComponent,
    MatDivider,
    NgTemplateOutlet,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSelectorComponent),
      multi: true,
    },
  ],
})
export class AsyncSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  required = input(false, { transform: booleanAttribute });
  filterable = input(false, { transform: booleanAttribute });
  searchQuery = '';
  private _queryParamsMapperService = inject(QueryParamsMapperService);
  selectedItem = model();
  /**
   * @description
   * The URL from which to fetch the data for the options.
   */
  dataUrl = input.required<string>();

  options = input<AsyncSelectorOptions>();
  defaultOptions: AsyncSelectorOptions = {
    loadStrategy: LoadStrategy.LAZY_ON_CLICK,
  };
  /**
   * @description
   * Specify any supported object by the data source to filter the target data.
   */
  filter = input<Record<string, unknown>>();
  /**
   * @description
   * The label to display for the select input.
   */
  label = input.required<string>();

  /**
   * @description
   * The key in the data object that will be used as the value for the options.
   */
  valueKey = input('id');
  searchKey = input('');

  /**
   * @description
   * An array to store the items fetched from the data source.
   */
  items: SafeAny[] = [];

  /**
   * @description
   * Holds the currently selected value in the dropdown.
   */
  value: SafeAny = null;

  /**
   * @description
   * Indicates whether the data is currently being loaded.
   */
  isLoading = false;

  /**
   * @description
   * Indicates whether any content has been loaded successfully.
   */
  hasContent = false;

  /**
   * @description
   * Stores any error message that occurs during data loading.
   */
  errorMessage: string | null = null;

  /**
   * @description
   * The current page number for paginated data loading.
   */
  private _queryParamsSignal = signal<PaginationQueryParams>({
    paging: {
      size: PAGINATION_ROWS_COUNTS[0],
      index: 0,
    },
    filter: {},
    orders: { id: 1 },
  });
  /**
   * @description
   * Indicates whether there is more data to be loaded (for pagination).
   */
  hasMoreData = true;

  /**
   * @description
   * Callback function to handle changes in the selected value.
   */
  onChange: SafeAny = () => {};

  /**
   * @description
   * Callback function to handle when the select input is touched.
   */
  onTouched: SafeAny = () => {};

  /**
   * @description
   * Reference to the MatSelect component in the template.
   */
  matSelect = viewChild(MatSelect);

  /**
   * @description
   * Injected HTTP service used to fetch data from the specified URL.
   */
  httpService = inject(HttpService);

  /**
   * @description
   * Template reference for the content that will be displayed inside the options.
   */
  content = contentChild.required(TemplateRef);
  selector = viewChild(MatSelect);
  opened = signal<boolean>(false);
  scrolled = signal<boolean>(false);
  selectAllOptionVisible = input(false, {
    transform: booleanAttribute,
  });
  cdr = inject(ChangeDetectorRef);
  private _firstPageLoaded = signal(false);
  private _subscriptions = new Subscription();
  /**
   *
   */
  constructor() {
    effect(
      () => {
        if (this.opened()) {
          switch (this.defaultOptions.loadStrategy) {
            case LoadStrategy.EAGER_ON_CLICK:
              this._subscriptions.add(this.loadData().subscribe(() => {}));
              break;
            case LoadStrategy.LAZY_ON_CLICK:
              if (!this._firstPageLoaded()) {
                this._resetPagination();
                this._subscriptions.add(
                  this.loadData().subscribe(() => {
                    this._firstPageLoaded.set(true);
                  }),
                );
              }
              if (this.scrolled()) {
                this.scrolled.set(false);
                if (!this.isLoading && this.hasMoreData) {
                  this._nextPage();
                  this.loadData().subscribe();
                }
              }
              break;
          }
        }
      },
      { allowSignalWrites: true },
    );
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    if (this.filterable() && !this.searchKey())
      throw Error('Please provide a search key to enable searching based on it.');

    this.defaultOptions = { ...this.defaultOptions, ...this.options() };

    switch (this.defaultOptions.loadStrategy) {
      case LoadStrategy.EAGER_ON_INIT:
        this._queryParamsSignal.update((params: PaginationQueryParams) => ({
          ...params,
          filter: { ...params.filter, ...this.filter() },
          paging: {
            index: 0,
            size: 100,
          },
        }));
        this._subscriptions.add(
          this.loadData().subscribe(() => {
            if (this.value) {
              const item = this.items.find((item) => item[this.valueKey()] === this.value);
              this.value = item;
              this.selectedItem.set(item);
            }
          }),
        );
        break;
      case LoadStrategy.LAZY_ON_INIT:
        this._queryParamsSignal.update((params: PaginationQueryParams) => ({
          ...params,
          filter: { ...params.filter, ...this.filter() },
        }));
        break;
    }
  }
  onOpenChange() {
    this.opened.set(true);
  }

  onScroll() {
    this.scrolled.set(true);
  }

  loadData(): Observable<void> {
    this.isLoading = true;
    this.errorMessage = '';
    if (
      this.defaultOptions.loadStrategy === LoadStrategy.EAGER_ON_CLICK ||
      this.defaultOptions.loadStrategy === LoadStrategy.LAZY_ON_CLICK
    ) {
      this.cdr.detectChanges();
      this.selector()?.open();
    }
    return this.fetchData().pipe(
      tap((data) => {
        // If we've fetched an initial item and this is the first page load
        const newItems = data.filter(
          (item) =>
            !this.items.some(
              (existingItem) => existingItem[this.valueKey()] === item[this.valueKey()],
            ),
        );
        this.items = [...this.items, ...newItems];
        this.isLoading = false;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError((_error) => {
        this.isLoading = false;
        this.errorMessage = 'An error occurred while attempting to load the data.';
        return of([]);
      }),
      map(() => void 0),
    );
  }

  fetchData(): Observable<SafeAny[]> {
    const url = `${this.dataUrl()}`;
    const params = new HttpParams({
      fromObject: {
        ...this._queryParamsMapperService.mapQueryParams<PaginationQueryParams>(this.queryParams),
      },
    });
    return this.httpService.get<PaginatedResponse<SafeAny>>(url, params).pipe(
      tap((data) => {
        this.hasMoreData = data.data.base.total > this.items.length;
      }),
      map((result) => result.data.items),
    );
  }
  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: SafeAny): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: SafeAny): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(_isDisabled: boolean): void {
    // Implement if needed
  }

  selectionChange(event: MatSelectChange) {
    this.onChange(event.value[this.valueKey()]);
    this.selectedItem.set(event.value);
  }
  clear() {
    this.value = null;
    this.selectedItem.set(null);
    this.onChange(this.value);
  }
  search(query: string) {
    this.searchQuery = query;
    this._resetPagination();
    // Keep selected items if they exist, otherwise clear the array
    if (this.selectedItem()) {
      const selectedItem = this.selectedItem() as SafeAny;
      this.items = this.items.filter(
        (item) => selectedItem[this.valueKey()] === item[this.valueKey()],
      );
    } else {
      this.items = [];
    }
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      ...params,
      filter: { ...params.filter, ...this.filter(), [this.searchKey()]: query },
    }));
    this._subscriptions.add(this.loadData().subscribe(() => {}));
  }
  private _fetchItemById(id: SafeAny): Observable<SafeAny> {
    const url = `${this.dataUrl()}/${id}`;
    return this.httpService.get<ApiResponse<SafeAny>>(url).pipe(
      map((response) => response.data),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      catchError((_error) => {
        return of(null);
      }),
    );
  }
  private _resetPagination() {
    this._queryParamsSignal.update((params) => ({
      ...params,
      paging: {
        ...params.paging,
        index: 0,
      },
    }));
  }
  private _nextPage() {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      paging: { ...params.paging, index: params.paging.index + 1 },
      filter: { ...params.filter },
      orders: { id: 1 },
    }));
  }
  get queryParams() {
    return this._queryParamsSignal();
  }
}

