import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import { SafeAny } from '@core/types';
import { ROUTES } from '@core/constants';
import { ApiResponse } from '@core/models';
import { FoldersEnum } from '@core/enums';

@Injectable({
  providedIn: 'root',
})
export class FileManagementService {
  private httpService = inject(HttpService);
  uploadFile(
    file: File,
    folder = FoldersEnum.Bank,
    uploadTo = ROUTES.file.upload,
  ): Observable<{ progress: number; fileId?: number }> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpService.http
      .post(this.httpService.buildUrl(`${uploadTo}?folder=${folder}`), formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(map((event) => this.getUploadEventMessage(event, file)));
  }

  deleteFile(fileId: string, deleteFrom = ROUTES.file.delete): Observable<boolean> {
    return this.httpService
      .delete<{ success: boolean }>(this.httpService.buildUrl(`${deleteFrom}/${fileId}`))
      .pipe(map((response) => response.success));
  }

  downloadFile(
    fileId: number,
    downloadFrom = ROUTES.file.download,
  ): Observable<{ progress: number; blob?: Blob }> {
    return this.httpService.http
      .get(this.httpService.buildUrl(`${downloadFrom}/${fileId}`), {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(map((event) => this.getDownloadEventMessage(event)));
  }

  private getUploadEventMessage(
    event: HttpEvent<SafeAny>,
    file: File,
  ): { progress: number; fileId?: number } {
    switch (event.type) {
      case HttpEventType.UploadProgress: {
        const progress = Math.round((100 * event.loaded) / (event.total ?? file.size));
        return { progress };
      }
      case HttpEventType.Response:
        return {
          progress: 100,
          fileId: (event.body as ApiResponse<{ fileAttachmentId: number }>).data.fileAttachmentId,
        };
      default:
        return { progress: 0 };
    }
  }

  private getDownloadEventMessage(event: HttpEvent<SafeAny>): { progress: number; blob?: Blob } {
    switch (event.type) {
      case HttpEventType.DownloadProgress: {
        const progress = Math.round((100 * event.loaded) / (event.total ?? 0));
        return { progress };
      }
      case HttpEventType.Response:
        return {
          progress: 100,
          blob: event.body as Blob,
        };
      default:
        return { progress: 0 };
    }
  }
}
